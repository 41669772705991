import React from "react";
import { Link } from 'react-router-dom'
import { Container, Header, Divider, Icon, Menu, Button, Accordion, List, ListContent, ListItem, ListDescription, ListHeader } from "semantic-ui-react";


const AboutComponent = () => {

  const panels = [
    {
      key: 'open',
      title: {content: (<span style={{fontWeight: "bold"}}>Does this site have any relation to OpenMHz?</span>)},
      content: [
        'This site (ClosedMHz) shares the source code with OpenMHz but is owned and hosted by a different group. Any references to OpenMHz found on this site are an error.',
      ].join(' '),
    },
    {
      key: 'moderation',
      title: {content: (<span style={{fontWeight: "bold"}}>Can my agency or audio be removed from ClosedMHz?</span>)},
      content: [
        'ClosedMHz generally does not remove frequencies or talkgroups from the site. We record unencrypted analog and digital transmissions for the local scanning community to listen to. We do however have the ability and put in our best effort to remove sensitive information that is captured as soon as possible.',
      ].join(' '),
    },
    {
      key: 'lockdown',
      title: {content: (<span style={{fontWeight: "bold"}}>Why was I asked to Login to Cloudflare/Microsoft?</span>)},
      content: [
        'ClosedMHz has a lockdown mode that can be used to lock certain feeds or the whole website. The administration team will activate this lock at their discretion during sensitive incidents or if the site is abused in anyway.',
      ].join(' '),
    },
    {
      key: 'commerical',
      title: {content: (<span style={{fontWeight: "bold"}}>Can my business use this site?</span>)},
      content: [
        'Yes! Anyone can use this site however we ask that audio from this site is not published publicly. Our goal is to keep this site small. The larger it gets the more frequently the lockdown mode may be used.',
      ].join(' '),
    },
    {
      key: 'sponsor',
      title: {content: (<span style={{fontWeight: "bold"}}>Can I pay for special access to the site?</span>)},
      content: [
        'At this time we are not accepting any money for this site. This may change in the future depending on costs. Certain individuals and groups have access to the site when it is locked down For more details contact the Site Admin. ',
      ].join(' '),
    }
  ]


  return (
    <div>
      <Menu fixed="top">
        <Link to="/"><Menu.Item link><Icon name='arrow left' /> Home</Menu.Item></Link>
        <Link to="/events"><Menu.Item link>Events</Menu.Item></Link>
        <Link to="/systems"><Menu.Item link>Listen</Menu.Item></Link>
      </Menu>
      <Container text>
        <Divider horizontal style={{ paddingTop: "4em", paddingBottom: "2em" }}><Header as="h1">About</Header></Divider>

        <p>ClosedMHz was created as a semi-private home to our many recorders located around the New England Region.</p>
        <p>Access to this site is free for personal use but may be restricted at any time at the discretion of the admin.</p>

        <Divider horizontal style={{ paddingTop: "4em", paddingBottom: "2em" }}><Header as="h2">FAQ</Header></Divider>
        <Accordion defaultActiveIndex={0} panels={panels} />
        <Divider/>
        <Divider horizontal style={{ paddingTop: "4em", paddingBottom: "2em" }}><Header as="h1">Disclaimer</Header></Divider>

        <p>ClosedMHz is not responsible for your use of the audio hosted on this website. Use of this online "scanner" may be a crime in your jurisdiction.</p>

      </Container>
    </div>
  );
}


export default AboutComponent;
